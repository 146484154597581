import {Controller} from "@hotwired/stimulus"

export class DivisionAPNController extends Controller {
    MIN_APNS = {
        starter: 1,
        mixed_starter: 1,
        advanced: 1,
        mixed_advanced: 2,
        expert: 3.5,
        elite: 3.5
    }

    MAX_APNS = {
        starter: 2.5,
        mixed_starter: 3,
        advanced: 4.5,
        mixed_advanced: 8,
        expert: 8,
        elite: 8
    }

    static targets = ["division", "name", "minAPN", "maxAPN"]

    connect() {
        this.setMinMax()
    }

    updateAPNInputs() {
        this.minAPNTarget.value = this.MIN_APNS[this.divisionName]
        this.maxAPNTarget.value = this.MAX_APNS[this.divisionName]
        this.setMinMax()
    }

    isMixed() {
        return this.nameTarget.value.toLowerCase().includes("mixed")
    }

    setMinMax() {
        if (this.divisionName == "") {
            return
        }
        let minAPN = this.MIN_APNS[this.divisionName]
        let maxAPN = this.MAX_APNS[this.divisionName]
        this.minAPNTarget.setAttribute("min", minAPN)
        this.minAPNTarget.setAttribute("max", maxAPN)
        this.maxAPNTarget.setAttribute("min", minAPN)
        this.maxAPNTarget.setAttribute("max", maxAPN)
        this.minAPNTarget.nextSibling.textContent = `Minimum APN muss zwischen ${minAPN} und ${maxAPN} liegen`
        this.maxAPNTarget.nextSibling.textContent = `Maximum APN muss zwischen ${minAPN} und ${maxAPN} liegen`
    }

    get divisionName() {
        let division = this.divisionTarget.value
        if (this.isMixed()) {
            division = `mixed_${division}`
        }
        return division
    }
}
