import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["tournamentType", "registrationType"]

    connect() {
        this.checkVisibility()
        this.tournamentTypeTarget.addEventListener('change', this.checkVisibility);
    }

    disconnect() {
        this.tournamentTypeTarget.removeEventListener('change', this.checkVisibility);
    }

    checkVisibility = () => {
        if (this.isFun()) {
            this.registrationTypeTarget.classList.remove("d-none");
        } else {
            this.registrationTypeTarget.classList.add("d-none");
        }
    }

    isFun() {
        return this.tournamentTypeTarget.value && this.tournamentTypeTarget.value === 'fun'
    }
}