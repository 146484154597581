import {Controller} from "@hotwired/stimulus"
import BlazeSlider from 'blaze-slider'

export class BannerController extends Controller {
    connect() {
        this.banner = new BlazeSlider(this.element, {
                all: {
                    enableAutoplay:     true,
                    autoplayInterval:   10000,
                    transitionDuration: 300,
                    draggable:          false,
                }
            }
        )
    }

    disconnect() {
    }
}
