// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import {StreamActions} from "@hotwired/turbo"
import "@rails/actiontext"
import "trix"
import * as bootstrap from "bootstrap"

import "./controllers"
import './object-fit-polyfill'
import './fontawesome'
import './trix-extensions'
import './dropdown-active'

document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(function (tooltipTriggerEl) {
    new bootstrap.Tooltip(tooltipTriggerEl)
})

StreamActions.redirect = function () {
    Turbo.visit(this.getAttribute("target"))
}
