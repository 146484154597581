import {Controller} from "@hotwired/stimulus"

export class NestedAttributesController extends Controller {
    static targets = ["links", "template"]

    connect() {
        this.wrapperClass = "nested-attributes"
    }

    add_association(event) {
        let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
        this.linksTarget.insertAdjacentHTML('afterend', content)
    }

    remove_association(event) {
        let wrapper = event.target.closest("." + this.wrapperClass)

        if (wrapper.dataset.newRecord === "true") {
            wrapper.remove()
        } else {
            wrapper.querySelector("input[name*='_destroy']").value = 1
            wrapper.style.display = 'none'
        }
    }
}
