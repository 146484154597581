import {Controller} from "@hotwired/stimulus"
import TomSelect from "tom-select";

export class TomSelectController extends Controller {
    static values = {
        maxOptions: {type: Number, default: null},
    }

    get maxOptions() {
        if (this.maxOptionsValue >= 0) {
            return this.maxOptionsValue
        } else {
            return null
        }
    }

    connect() {
        this.tomSelect = new TomSelect(this.element, {
            maxOptions: this.maxOptions,
        })
    }

    disconnect() {
        this.tomSelect.destroy()
    }
}
